<template>
  <div>
    <Manageskills />
  </div>
</template>
<script>
import Manageskills from "@/components/skills/Manageskills";
export default {
  components: {
    Manageskills,
  },
  created() {
  },
};
</script>